import Router from 'next/router';
import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import omitBy from 'lodash/omitBy';
import get from 'lodash/get';

const isServer = typeof window === 'undefined';

const redirectOnClient = to => {
  if (to.startsWith('http')) {
    return window.location.replace(to);
  }
  Router.push(to);
};

const redirectOnServer = (to, res) => {
  res.writeHead(301, { Location: to });
  return res.end();
};

export const compose = (...functions) => input => functions.reduce(
  (acc, fn) => fn(acc),
  input
);

export const escapeHtml = (htmlString) => {
  const { replace } = '';

  // escape
  const ca = /[<>]/g;

  const esca = {
    '<': '&lt;',
    '>': '&gt;',
  };
  const pe = m => esca[m];
  return replace.call(htmlString, ca, pe);
};

export const escapeQuotationMarks = (text) => (text?.replace(/"/g, '\\"'));

export const textAbstract = (text, length) => {
  if (text == null) return '';
  if (text.length <= length) return text;
  let abstractText = text.substring(0, length);
  const last = abstractText.lastIndexOf(' ');
  abstractText = abstractText.substring(0, last);
  return `${abstractText}...`;
};

export const getCleanId = (id) => {
  if (typeof id !== 'string') {
    return id;
  }
  return id.replace(/vx-/gi, '');
};

export const pickProperty = propertyName => (obj = {}) => get(obj, propertyName);
export const includeIfExists = (key, value) => (value === undefined || !key ? {} : { [key]: value });
export const debugIdentity = message => arg => {
  console.log(message, arg);
  return arg;
};
export const flattenArray = (accumulator, currentValue) => accumulator.concat(currentValue);
export const unique = (flatArray = []) => [...new Set(flatArray)];
export const numberRange = (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx);

export const validateRoute = (path) => {
  // for compliance-ui
  if (/http/.test(path) && /bbfc\.co\.uk/.test(path)) return path;
  // for internal routes
  if (path?.startsWith('/')) return path;
  return '/';
};

export const redirectTo = (path, nextUrl, { res }) => {
  const redirectPath = nextUrl ? `${path}?next=${nextUrl}` : path;

  if (isServer && res) return redirectOnServer(redirectPath, res);
  if (!isServer) return redirectOnClient(redirectPath);
};

export const omitSameValues = (obj1, obj2) => (
  omitBy(obj1, (v, k) => {
    if (Array.isArray(obj2[k]) && Array.isArray(v)) return obj2[k].length === v.length;
    return obj2[k] === v;
  })
);

export const changeCaseConstantToSentence = text => capitalize(lowerCase(text));

export const changeStringToTitleCase = text => (text || '').toLowerCase().replace(/^.|(?: )./g, t => (t || '').toUpperCase());

export const slugify = (str = '') => str.split(' ').join('_');

export const removeIllegalsFromFileName = (str = '', replacement = '_') => str.replace(/(?!\.[^.]+$)\.|[^\w.-]+/g, replacement);

export const displayBooleanField = value => value ? 'Yes' : 'No'; // eslint-disable-line no-confusing-arrow

export const passwordFitTheRules = (password = '') => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})/.test(password);

export const safeParseJSON = input => {
  try {
    return JSON.parse(input);
  } catch (e) {
    return input;
  }
};

export const groupBy = (objectArray, property) => objectArray.reduce((acc, obj) => {
  const key = get(obj, property);
  if (!acc[key]) acc[key] = [];
  acc[key].push(obj);
  return acc;
}, {});

export const roundTo = (num, dec = 2) => Math.round(num * (10 ** dec)) / (10 ** dec);

export const normalizeItemResult = (item = {}) => {
  const fields = get(item, 'metadata.timespan.0.field') || [];
  const result = fields.reduce((acc, curr) => {
    if ((curr.value || []).length > 1) return { ...acc, [curr.name]: (curr.value || []).map((v) => v.value) };
    return { ...acc, [curr.name]: get(curr, 'value.0.value') };
  }, {});

  return {
    id: item.id,
    ...result,
  };
};

export const normalizeItemsResult = (items = []) => items.map(normalizeItemResult);

const getLevel = (tree, parentId, nodeKey, parentKey) => {
  const parent = tree.find(el => el[nodeKey] === parentId);
  return parent ? getLevel(tree, parent[parentKey], nodeKey, parentKey) + 1 : 1;
};

const getDepth = (tree, nodeId, nodeKey, parentKey) => {
  const children = tree.filter(el => el[parentKey] === nodeId);
  const depths = children.map(child => getDepth(tree, child[nodeKey], nodeKey, parentKey));
  return children.length ? Math.max(...depths) + 1 : 1;
};

const isDescendant = (tree, nodeId, targetNodeId, nodeKey, parentKey) => {
  const children = tree.filter(el => el[parentKey] === targetNodeId);
  const isChild = children.some(child => child[nodeKey] === nodeId);
  return isChild || children.some(child => isDescendant(tree, nodeId, child[nodeKey], nodeKey, parentKey));
};

const normalizeTree = (tree, nodeKey, parentKey) => tree.filter(el => el[nodeKey] && el[parentKey] !== el[nodeKey]);

/**
 *
 * @param {Array<object>} tree the flat structure of the tree elements
 * @param {number} parentId the unique identifier of the node's parent (must be the value of parentKey prop)
 * @param {string} nodeKey the property name (key) of the unique node identifier
 * @param {string} parentKey the property name (key) of the parent's identifier
 * @returns {number} the level of a given node as number
 */
export const getNodeLevel = (tree, parentId, nodeKey, parentKey) => {
  const validTreeItems = normalizeTree(tree, nodeKey, parentKey);
  return getLevel(validTreeItems, parentId, nodeKey, parentKey);
};

/**
 *
 * @param {Array<object>} tree the flat structure of the tree elements
 * @param {number} nodeId the unique identifier of the node (must be the value of nodeKey prop)
 * @param {string} nodeKey the property name (key) of the unique identifier
 * @param {string} parentKey the property name (key) of the parent's identifier
 * @returns {number} the maximum depth of a given node as number
 */
export const getNodeDepth = (tree, nodeId, nodeKey, parentKey) => {
  const validTreeItems = normalizeTree(tree, nodeKey, parentKey);
  return getDepth(validTreeItems, nodeId, nodeKey, parentKey);
};

/**
 *
 * @param {Array<object>} tree the flat structure of the tree elements
 * @param {number} nodeId the unique identifier of the node
 * @param {number} targetNodeId the unique identifier of the target node
 * @param {string} nodeKey the property name (key) of the unique identifier
 * @param {string} parentKey the property name (key) of the parent's identifier
 * @returns {boolean}
 */
export const isDescendantOf = (tree, nodeId, targetNodeId, nodeKey, parentKey) => {
  const validTreeItems = normalizeTree(tree, nodeKey, parentKey);
  return isDescendant(validTreeItems, nodeId, targetNodeId, nodeKey, parentKey);
};

const utils = {
  compose,
  pickProperty,
  includeIfExists,
  debugIdentity,
  flattenArray,
  unique,
  redirectTo,
  changeCaseConstantToSentence,
  changeStringToTitleCase,
  slugify,
  omitSameValues,
  displayBooleanField,
  passwordFitTheRules,
  safeParseJSON,
  groupBy,
  roundTo,
  normalizeItemResult,
  normalizeItemsResult,
  getNodeLevel,
  getNodeDepth,
  isDescendantOf,
};

export default utils;
